import React, {useEffect, useState} from "react";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Button} from "@mui/material";
import EstimateTemplateService from "../../../services/EstimateTemplateService";
import {Dialog} from "primereact/dialog";
import ProjectEstimateTemplate from "./ProjectEstimateTemplate";
import {toast} from "react-toastify";
import EmployeeTableForeman from "./tables/EmployeeTableForeman";
import GeneralUtils from "../../../utils/GeneralUtils";
import TailGateTalkFormService from "../../../services/TailGateTalkFormService";
import TailGateTalkForm from "../tail-gate-talk/TailGateTalkForm";
import PreJobSafetyFormService from "../../../services/PreJobSafetyFormService";
import EmergencyService from "../../../services/EmergencyService";
import PreJobSafetyForm from "../pre-job-safety/PreJobSafetyForm";
import EmergencyForm from "../emergency-form/EmergencyForm";

export default function ProjectEstimateTemplateList() {
    const estimateTemplateService = new EstimateTemplateService();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [showDialogForeman, setShowDialogForeman] = useState(false);
    const [selectedData, setSelectedData] = useState({});
    const [role, setRole] = useState({});
    const [expandedRows, setExpandedRows] = useState([]);
    const [tailGateFormList, setTailGateFormList] = useState([]);
    const [preJobSafetyFormList, setPreJobSafetyFormList] = useState([]);
    const [emergencyFormList, setEmergencyFormList] = useState([]);
    const tailGateTalkFormService = new TailGateTalkFormService();
    const preJobSafetyFormService = new PreJobSafetyFormService();
    const emergencyService = new EmergencyService();

    useEffect(() => {
        let role = localStorage.getItem('role');
        setRole(role);
        findAll();
    }, []);

    const updateDt = () => {
        findAll();
        toast.success("Operation success.");
    }

    const findAll = () => {
        setLoading(true);
        estimateTemplateService.findAll().then(res => {
            if (res.status == 200) {
                setData(res.data);
                setLoading(false);
                setShowDialog(false);
                setShowDialogForeman(false);
            }
        }).catch(err => toast.error("Error : ProjectEstimateTemplateList -> findAll()"))
    }

    const onEdit = (row) => {
        setShowDialog(true);
        setSelectedData(row);
    }

    const onEnteringTimes = (row) => {
        setShowDialogForeman(true);
        setSelectedData(row);
    }

    const renderActionBody = (row) => {
        return (
            <React.Fragment>
                {role === 'ROLE_FOREMAN' &&
                    <Button size="large" variant="contained" color="warning" onClick={() => onEnteringTimes(row)}>
                        <i className="fa fa-clock-o"></i>
                    </Button>}
                <Button style={role === 'ROLE_FOREMAN' ? {marginLeft: '1em'} : {}} size="large" variant="contained"
                        color="warning" onClick={() => onEdit(row)}>
                    <i className="fa fa-pencil"></i>
                </Button>
            </React.Fragment>
        );
    }

    const renderDialog = () => {
        return (
            <div className="dialog-demo">
                <div className="card">
                    <Dialog visible={showDialog} onHide={() => setShowDialog(false)}
                            maximized={true} baseZIndex={1500}
                    >
                        <ProjectEstimateTemplate selectedData={selectedData} updateDt={updateDt}/>
                    </Dialog>
                </div>
            </div>
        )
    }

    const renderForemanDialog = () => {
        return (
            <div className="dialog-demo">
                <div className="card">
                    <Dialog visible={showDialogForeman} onHide={() => setShowDialogForeman(false)} maximized={false}
                            breakpoints={{'960px': '75vw', '640px': '100vw'}} style={{width: '50vw'}} maximizable={true}
                            baseZIndex={1501}
                    >
                        <EmployeeTableForeman selectedData={selectedData} updateDt={updateDt}/>
                    </Dialog>
                </div>
            </div>
        )
    }

    const onRowExpand = (event) => {
        let estimateTemplateId = event.data.id;
        tailGateTalkFormService.findByEstimateTemplateId({estimateTemplateId}).then(res=>{
            if (res.status == 200){
                setTailGateFormList(res.data);
            }
        })

        preJobSafetyFormService.findByEstimateTemplateId({estimateTemplateId}).then(res=>{
            if (res.status == 200){
                setPreJobSafetyFormList(res.data);
            }
        })

        emergencyService.findByEstimateTemplateId({estimateTemplateId}).then(res=>{
            if (res.status == 200){
                setEmergencyFormList(res.data);
            }
        })
    }

    const onRowCollapse=()=>{
        setTailGateFormList([]);
        setPreJobSafetyFormList([]);
        setEmergencyFormList([]);
    }

    const rowExpansionTemplate = (data) => {
        return (
            <div>
                {tailGateFormList.map(data=>{
                   return <TailGateTalkForm isShow={true} selectedData={data}/>
                })}
                {preJobSafetyFormList.map(data=>{
                    return <PreJobSafetyForm isShow={true} selectedData={data}/>
                })}
                {emergencyFormList.map(data=>{
                    return <EmergencyForm isShow={true} selectedData={data}/>
                })}
            </div>
        );
    }

    let dt = <div>
        <div className="card">
            <DataTable value={data} paginator responsiveLayout="scroll"
                       onRowExpand={onRowExpand}
                       onRowCollapse={onRowCollapse}
                       expandedRows={expandedRows}
                       onRowToggle={(e) => setExpandedRows(e.data)}
                       rowExpansionTemplate={rowExpansionTemplate}
                       dataKey="id"
                       selection={selectedData}
                       loading={loading}
                       paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                       currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={10}
                       rowsPerPageOptions={[10, 20, 50]}
            >
                <Column expander style={{ width: '3em' }} />
                <Column header='Operations' body={renderActionBody}
                        headerStyle={role === 'ROLE_FOREMAN' ? {width: '12em', textAlign: 'center'} : {
                            width: '8em',
                            textAlign: 'center'
                        }}
                        bodyStyle={{textAlign: 'center', overflow: 'visible'}}></Column>

                <Column field="project_name" header="Job Number"></Column>
                <Column field="date" header="Date Of Estimate"></Column>
                <Column field="project_category_name" header="Category Name"></Column>
                <Column field="estimate_project_hour" header="Estimate Project Hour"></Column>
                <Column field="status" header="Project Status"></Column>
                <Column field="profit" body={e => '$' + GeneralUtils.changeDecimalSeperator('' + e.profit, '.', ',')}
                        header="Profit"></Column>
            </DataTable>
            {renderDialog()}
            {renderForemanDialog()}
        </div>

    </div>;


    return dt;
}