export const HAZARDOUS_OPTIONS = [
    {
        name: "Asbestos",
        price: 1.2
    },
    {
        name: "Liquid Concrete",
        price: 2.0
    },
    {
        name: "Concrete dust",
        price: 2.5
    },
    {
        name: "Propane use and storages",
        price: 3.0
    },
    {
        name: "Asphalt",
        price: 3.5
    }
];